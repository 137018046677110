/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { shopifyClient as client } from 'services/shopify'

//import { gql } from 'graphql-request'
//import queryShopify from '../graphql/helpers/queryShopify'
//import CHECKOUT_FRAGMENT from '../graphql/fragments/shopify/checkoutFragment'
import { set, get } from 'utils/local-storage'

const CheckoutContext = React.createContext()

const initialState = {
	checkout: null,
	checkoutUrl: null,
	loading: true,
	cartOpen: false,
}

class CheckoutProvider extends React.Component {
	constructor(props) {
		super(props)
		this.state = initialState
	}

	componentDidMount() {
		this.initializeCheckout()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState?.checkout || (prevState?.checkout?.totalPrice?.amount !== this.state?.checkout?.totalPrice?.amount)) {
			this.getCheckoutUrl()
		}
	}

	toggleCart = (cartOpen) => this.setState({ cartOpen })

	initializeCheckout = async () => {
		let checkout;
		const checkoutId = await this.getCheckoutId()
		if (checkoutId) {
			checkout = await this.getCheckout(checkoutId)
		} else {
			checkout = await this.createCheckout()
		}
		if (checkout?.completedAt || !checkout) {
			checkout = await this.createCheckout()
		}
		set('shopify-checkout-id', checkout?.id)
		this.setState({ checkout, loading: false })
	}

	createCheckout = async () => {
		const { checkout } = client;
		return await checkout.create()
	}

	//////
	getCheckoutUrl = async () => {
		const lineItems = this.state.checkout.lineItems.map((line) => ({
      variantId: line.variant.id,
      quantity: line.quantity,
    }))

		const storefrontAccessToken = process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN; // Replace this
		const shopifyDomain = "milton-textiles.myshopify.com"; // Replace this
	
		const query = `
			mutation checkoutCreate($lineItems: [CheckoutLineItemInput!]!) {
				checkoutCreate(input: { lineItems: $lineItems }) {
					checkout {
						webUrl
					}
					userErrors {
						field
						message
					}
				}
			}
		`;
	
		const variables = { lineItems };
	
		try {
			const response = await fetch(`https://${shopifyDomain}/api/2024-01/graphql.json`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-Shopify-Storefront-Access-Token": storefrontAccessToken,
				},
				body: JSON.stringify({ query, variables }),
			});
	
			const result = await response.json();
			
			if (result.errors) {
				console.error("GraphQL Errors:", result.errors);
				return null;
			}
	
			const checkoutUrl = result.data.checkoutCreate.checkout.webUrl;
			console.log('GOT checkout url: ', checkoutUrl)
			this.setState({ checkoutUrl: checkoutUrl })
			return checkoutUrl;
		} catch (error) {
			console.error("Error creating checkout:", error);
			return null;
		}
	}
	//////

	getCheckout = async (checkoutId) => {
		const { checkout } = client;
		return await checkout.fetch(checkoutId);
	}

	getCheckoutId = async () => {
		const { checkout } = this.state
		let checkoutId = checkout?.id
		if (!checkoutId) {
			checkoutId = await get('shopify-checkout-id')
		}
		return checkoutId
	}

	getLineItems = () => {
		const { checkout } = this.state
		return checkout ? Array.from(checkout?.lineItems) : []
	}

	updateLineItems = async ({ lineItems }) => {
		const checkoutId = await this.getCheckoutId()
		const lineItemsUpdate = lineItems.map(({id, quantity}) => ({id, quantity}))
		const checkout = await client.checkout.updateLineItems(checkoutId, lineItemsUpdate)
		if (checkout) this.setState({ checkout })
	}

	emptyLineItems = () => {
		this.updateLineItems({ lineItems: [] })
	}

	addLineItem = async ({ variantId, quantity = 1, toggleCart = true }) => {
		this.setState({ loading: true })
		if (toggleCart) this.toggleCart(true)
		const lineItems = this.getLineItems()
		const lineItem = lineItems.find((lineItem) => lineItem.variant.id === variantId)
		if (lineItem) {
			await this.updateQuantity({ id: lineItem.id, quantity: lineItem.quantity + 1 })
		} else {
			const checkoutId = await this.getCheckoutId()
			const checkout = await client.checkout.addLineItems(checkoutId, { variantId, quantity })
			if (checkout) this.setState({ checkout })
		}
		this.setState({ loading: false })
	}

	updateQuantity = async ({ id, quantity }) => {
		if (!quantity) {
			this.removeLineItem({ id })
		} else {
			this.setState({ loading: true })
			const lineItems = this.getLineItems()
			const lineItemsUpdate = lineItems.map((lineItem) => {
				if (lineItem.id === id) {
					return { id, quantity }
				} else {
					return lineItem
				}
			})
			await this.updateLineItems({ lineItems: lineItemsUpdate })
			this.setState({ loading: false })
		}
	}

	removeLineItem = async ({ id }) => {
		this.setState({ loading: true })
		const checkoutId = await this.getCheckoutId()
		const checkout = await client.checkout.removeLineItems(checkoutId, [id])
		if (checkout) this.setState({ checkout })
		this.setState({ loading: false })
	}

	render() {
		const { children } = this.props
		return (
			<CheckoutContext.Provider
				value={{
					...this.state,
					addLineItem: this.addLineItem,
					updateQuantity: this.updateQuantity,
					removeLineItem: this.removeLineItem,
					decrementLineItem: this.decrementLineItem,
					toggleCart: this.toggleCart,
					emptyLineItems: this.emptyLineItems,
					getLineItems: this.getLineItems
				}}
			>
				{children}
			</CheckoutContext.Provider>
		)
	}
}

export default CheckoutProvider

export const withCheckoutContext = (Component) => {
	return (props) => (
		<CheckoutContext.Consumer>{(context) => (<Component {...props} checkoutContext={context} />)}</CheckoutContext.Consumer>
	)

}


